import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import {
  Badge,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import { utilsHelper } from "../helpers/utilsHelper";
import { employeeSafetyCertificationsApi } from "../services/employeeSafetyCertificationsService";
import InformationModal from "./InformationModal";
import Loader from "./Loader";
import DocumentPreviewModal from "./admin/DocumentPreviewModal";

const SafetyCertificationsModal = ({ onClose, employeeId }) => {
  const [loading, setLoading] = useState(false);
  const [certifications, setCertifications] = useState([]);

  const [documentPreviewModal, setDocumentPreviewModal] = useState({
    isOpen: false,
    file: null,
    onClose: null,
  });

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  useEffect(() => {
    if (employeeId) {
      setLoading(true);

      const init = async () => {
        const response = await employeeSafetyCertificationsApi.getAll({
          employeeId,
        });

        const data = response;

        if (data) {
          setCertifications(data);
        }

        setLoading(false);
      };

      init();
    }
  }, [employeeId]);

  const downloadFile = async (id) => {
    await employeeSafetyCertificationsApi.getSignedFile(id);
  };

  const isExpired = (certification) => {
    const today = new Date();
    const expirationDate = new Date(certification.certExpiration);

    return today > expirationDate;
  };

  const isToExpire = (certification) => {
    const today = new Date();
    const expirationDate = new Date(certification.certExpiration);
    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 60;
  };

  const openDocumentPreview = async (certification) => {
    const isPDF = certification.certLink.endsWith(".pdf");
    const file = await employeeSafetyCertificationsApi.getSignedFile(
      certification.id,
      { type: "blob" }
    );

    if (isPDF) {
      setDocumentPreviewModal({
        isOpen: true,
        file: window.URL.createObjectURL(file),
        onClose: () => setDocumentPreviewModal({ isOpen: false }),
      });
    } else {
      setInformationModal({
        isOpen: true,
        title: "Document Preview",
        rawBody: true,
        body: (
          <div className="d-flex flex-column text-center">
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              {({ zoomIn, zoomOut }) => (
                <React.Fragment>
                  <div
                    style={{ position: "absolute", zIndex: 100 }}
                    className="tools mt-2"
                  >
                    <Button
                      className="rounded mx-2 btn-light border"
                      onClick={() => zoomOut()}
                    >
                      -
                    </Button>
                    <Button
                      className="rounded btn-light border"
                      onClick={() => zoomIn()}
                    >
                      +
                    </Button>
                  </div>
                  <TransformComponent>
                    <img
                      className="img-fluid rounded"
                      src={certification.certLink}
                      alt="Document"
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>
        ),
        onClose: () => setInformationModal({ isOpen: false }),
      });
    }
  };

  const hasCertifications = certifications.length > 0;

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={informationModal.onClose}
    />
  ) : documentPreviewModal.isOpen ? (
    <DocumentPreviewModal {...documentPreviewModal} pageWidth={800} />
  ) : (
    <Modal isOpen={true} toggle={onClose} size="md">
      <ModalHeader>Safety Certifications</ModalHeader>

      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : hasCertifications ? (
          <Table className="border col-12 px-0 mb-0 small">
            <tbody>
              {certifications.map((certification) => (
                <tr>
                  <td className="font-weight-bold">
                    {certification.certDescription}
                  </td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Badge
                        color={
                          isExpired(certification)
                            ? "danger"
                            : isToExpire(certification)
                            ? "six"
                            : "eight"
                        }
                      >
                        {utilsHelper.formatUTCDate(
                          certification.certExpiration,
                          "MM/DD/YYYY"
                        )}
                      </Badge>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="rounded mr-2"
                        color="seven"
                        size="sm"
                        disabled={certification.certLink ? false : true}
                        onClick={() => openDocumentPreview(certification)}
                      >
                        View
                      </Button>

                      <Button
                        className="rounded"
                        color="link"
                        size="sm"
                        onClick={() => downloadFile(certification.id)}
                        disabled={certification.certLink ? false : true}
                      >
                        <FontAwesomeIcon
                          size="lg"
                          icon={faFileDownload}
                          className="text-seven"
                        />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div className="text-center text-muted mt-4 mb-4 small">
            No data has been recorded
          </div>
        )}
      </ModalBody>

      <ModalFooter className="d-flex justify-content-end">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SafetyCertificationsModal;
