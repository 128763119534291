import {
  faChevronDown,
  faChevronRight,
  faDatabase,
  faDownload,
  faExclamationCircle,
  faPlus,
  faSignature,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Row,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import Expense from "../../components/admin/workOrderDetails/Expense";
import JobExpensesFlexMonsterModal from "../../components/admin/workOrderDetails/JobExpensesFlexMonsterModal";
import ExpensesReportModal from "../../components/admin/ExpensesReportModal";
import InformationModal from "../../components/InformationModal";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../helpers/utilsHelper";
import TooltipItem from "../../components/TooltipItem";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AddWoExpense from "../../components/admin/AddWoExpense";
import { expensesApi } from "../../services/expensesServices";
import ConfirmationModal from "../../components/ConfirmationModal";
import UpdateWoExpense from "../../components/admin/UpdateWoExpense";
import { generalExpensesApi } from "../../services/generalExpensesServices";
import AddWoCashAdvance from "../../components/admin/AddWoCashAdvance";
import { useAuth } from "../../providers/authProvider";
import { cashAdvanceApi } from "../../services/cashAdvanceServices";
import { is } from "date-fns/locale";

const USER_ROLE_SUPERVISOR = 3;
const STATUS_CANCELED = 10;

const WorkOrderExpenses = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [addWoExpense, setAddWoExpense] = useState(false);
  const [updateWoExpense, setUpdateWoExpense] = useState(false);

  const [addWoCashAdvance, setAddWoCashAdvance] = useState(false);

  const isReadOnly =
    utilsHelper.isReadOnly(authContext) ||
    workOrderDetails.workOrder.statusId === STATUS_CANCELED;

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [showExpensesReportModal, setShowExpensesReportModal] = useState(false);
  const [showExpensesFlexReportModal, setShowExpensesFlexReportModal] =
    useState(false);

  const openSignatureModal = (url, description) => {
    if (!url) {
      return setInformationModal({
        isOpen: true,
        title: `Signature Preview`,
        body: "Signature not available",
      });
    }
    setInformationModal({
      isOpen: true,
      title: "Signature Preview",
      rawBody: true,
      body: (
        <>
          <div className="d-flex flex-column text-center">
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              {({ zoomIn, zoomOut, ...rest }) => (
                <React.Fragment>
                  <div
                    style={{ position: "absolute", zIndex: 100 }}
                    className="tools"
                  >
                    <Button
                      className="rounded btn-light border"
                      onClick={() => {
                        utilsHelper.downloadFile(url);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <Button
                      className="rounded mx-2 btn-light border"
                      onClick={() => zoomOut()}
                    >
                      -
                    </Button>
                    <Button
                      className="rounded btn-light border"
                      onClick={() => zoomIn()}
                    >
                      +
                    </Button>
                  </div>
                  <TransformComponent>
                    <img
                      className="img-fluid rounded"
                      src={url}
                      alt={description}
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
            <span className="mt-2">{description}</span>
          </div>
        </>
      ),
    });
  };

  const onEdit = (expense) => setUpdateWoExpense(expense);

  const onDelete = (expense) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        submitDelete(expense);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Expense",
      body: `Are you sure you want to delete this expense?`,
    });

  const onDeleteCashAdvance = (id) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        submitDeleteCashAdvance(id);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Cash Advance",
      body: `Are you sure you want to delete this cash advance?`,
    });

  const submitDelete = async (expense) => {
    try {
      await (expense.isGeneral
        ? generalExpensesApi.deleteGeneralExpense(expense.id)
        : expensesApi.deleteExpense(expense.id));
      setConfirmationModal(initConfirmationModal);
      setInformationModal({
        isOpen: true,
        title: `Expenses`,
        body: `Expense deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Expense`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const submitDeleteCashAdvance = async (id) => {
    try {
      await cashAdvanceApi.deleteCashAdvance(id);
      setConfirmationModal(initConfirmationModal);
      setInformationModal({
        isOpen: true,
        title: `Cash Advance`,
        body: `Cash Advance deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Cash Advance`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  return (
    <Row>
      <Col sm="12">
        <div className="d-flex justify-content-between border-bottom">
          <h4>Expenses</h4>
          {!isReadOnly ? (
            <div className="d-flex justify-content-between align-items-center">
              <Button
                size="sm"
                color="primary rounded ml-2"
                onClick={() => setAddWoExpense(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Add Expense</span>
              </Button>
              <Button
                size="sm"
                color="primary rounded ml-2"
                onClick={() => setAddWoCashAdvance(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Add Cash Advance</span>
              </Button>
            </div>
          ) : null}
        </div>
        {!workOrderDetails.dates ||
        !Object.keys(workOrderDetails.dates).length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          <>
            <Row className="mt-3">
              <Col className="col-12">
                <ListGroup>
                  <ListGroupItem
                    className="d-flex align-items-center py-2 bg-tertiary text-white text-center justify-content-center font-weight-bold"
                    tag="div"
                  >
                    Work Order Totals
                  </ListGroupItem>
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center py-2"
                    tag="div"
                  >
                    <div className="col-6 px-0">Expenses</div>
                    <div className="col-6 px-0 text-right">
                      {utilsHelper.formatCurrency(
                        workOrderDetails.totalExpenses
                      )}
                    </div>
                  </ListGroupItem>
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center py-2"
                    tag="div"
                  >
                    <div className="col-6 px-0">Cash Advance</div>
                    <div className="col-6 px-0 text-right">
                      {utilsHelper.formatCurrency(
                        workOrderDetails.totalPerdiem
                      )}
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12">
                <div className="d-flex d-flex-row justify-content-between border-bottom">
                  <h4>Daily Reports</h4>
                  <div>
                    <Button
                      color="tertiary"
                      size="sm"
                      className="ml-2 rounded"
                      onClick={() => setShowExpensesFlexReportModal(true)}
                    >
                      <FontAwesomeIcon icon={faDatabase} className="mr-2" />
                      <span>Flex Report</span>
                    </Button>
                    <Button
                      color="tertiary"
                      size="sm"
                      className="ml-2 rounded"
                      onClick={() => setShowExpensesReportModal(true)}
                    >
                      <FontAwesomeIcon icon={faDownload} className="mr-2" />
                      <span>CSV</span>
                    </Button>
                  </div>
                </div>
                {Object.keys(workOrderDetails.dates)
                  .sort(utilsHelper.sortDays)
                  .map((date) => {
                    const crewWorkDays =
                      workOrderDetails.dates[date].crewWorkDays;
                    const generalExpenses =
                      workOrderDetails.dates[date].generalExpenses;
                    return (
                      <div className="mt-3" key={date}>
                        <Button
                          key={date}
                          onClick={() =>
                            setWorkOrderDetails({
                              action: ACTIONS.TOGGLE_DATE,
                              payload: { date },
                            })
                          }
                          style={{ width: "100%" }}
                        >
                          <div className="d-flex flex-row justify-content-between m-1 align-middle">
                            <span>{moment(date).format("MM/DD/YYYY")}</span>
                            <FontAwesomeIcon
                              icon={
                                workOrderDetails.activeDates.has(date)
                                  ? faChevronDown
                                  : faChevronRight
                              }
                              fixedWidth
                              className="my-1"
                            />
                          </div>
                        </Button>
                        <Collapse
                          isOpen={workOrderDetails.activeDates.has(date)}
                          className="p-2"
                        >
                          {crewWorkDays
                            .sort((a, b) =>
                              a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
                            )
                            .map((crewWorkDay) => {
                              const supervisorEmployee =
                                utilsHelper.getSupervisorEmployee(
                                  crewWorkDay.employees
                                );
                              const supervisor = supervisorEmployee
                                ? `${supervisorEmployee?.employee.firstName} ${supervisorEmployee?.employee.lastName}`
                                : "No Supervisor Assigned";
                              return (
                                <div
                                  className="col-12 mt-2 border px-0 rounded"
                                  key={crewWorkDay.id}
                                >
                                  <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                                    <div className="d-flex align-items-center col-3 px-0">
                                      <small className="text-white px-1 border border-white rounded font-weight-bold">
                                        {utilsHelper.outageLabels(
                                          crewWorkDay.outage
                                        )}
                                      </small>
                                      <small className="text-white px-1 border border-white rounded font-weight-bold ml-2">
                                        {crewWorkDay.completed
                                          ? "Day Closed"
                                          : "Day Open"}
                                      </small>
                                    </div>
                                    <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                      {utilsHelper.capitalize(
                                        crewWorkDay.shift
                                      )}{" "}
                                      Crew
                                    </div>
                                    <div className="d-flex align-items-center col-3 px-0 justify-content-end">
                                      <small className="text-white text-truncate px-1 border border-white rounded font-weight-bold">
                                        {supervisor}
                                      </small>
                                    </div>
                                  </div>
                                  {crewWorkDay.expenses.length === 0 &&
                                  crewWorkDay.perDiems.length === 0 ? (
                                    <div className="text-center text-muted my-2 small">
                                      No expenses or cash advance have been
                                      recorded for the shift
                                    </div>
                                  ) : (
                                    <div className="d-flex col-12 flex-column">
                                      <Row className="border-top border-bottom border-left-0 border-right-0 d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold">
                                        EXPENSES
                                      </Row>
                                      <Row className="d-flex flex-wrap">
                                        {crewWorkDay.expenses.length ? (
                                          crewWorkDay.expenses.map(
                                            (expense) => (
                                              <Expense
                                                expense={expense}
                                                key={expense.id}
                                                onDelete={onDelete}
                                                onEdit={onEdit}
                                              />
                                            )
                                          )
                                        ) : (
                                          <div className="py-2 w-100 small text-center text-muted">
                                            No expenses added
                                          </div>
                                        )}
                                      </Row>
                                      <Row className="d-flex flex-column">
                                        <ListGroup
                                          className="col-12 px-0"
                                          flush
                                        >
                                          <ListGroupItem
                                            className="border-top border-left-0 border-bottom border-right-0 d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
                                            tag="div"
                                          >
                                            CASH ADVANCE
                                          </ListGroupItem>
                                          {crewWorkDay.perDiems.map(
                                            (perDiem) => (
                                              <ListGroupItem
                                                key={perDiem.id}
                                                className="d-flex justify-content-between align-items-center py-2 border-left-0"
                                                tag="div"
                                              >
                                                <div className="d-flex align-items-center">
                                                  <Button
                                                    className="pl-0"
                                                    color="link"
                                                    onClick={() =>
                                                      openSignatureModal(
                                                        perDiem.signatureUrl,
                                                        `${perDiem.employee.firstName} ${perDiem.employee.lastName}`
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faSignature}
                                                    />
                                                  </Button>
                                                  {perDiem.perDiemAuthor &&
                                                  perDiem.perDiemAuthor
                                                    .userRoleId !==
                                                    USER_ROLE_SUPERVISOR ? (
                                                    <TooltipItem
                                                      className="mr-2"
                                                      id={`${perDiem.id}-tooltip`}
                                                      title={`Created by ${`${perDiem.perDiemAuthor.firstName} ${perDiem.perDiemAuthor.lastName}`}`}
                                                    >
                                                      <FontAwesomeIcon
                                                        size="lg"
                                                        icon={
                                                          faExclamationCircle
                                                        }
                                                        className={`text-warning`}
                                                        style={{
                                                          paddingBottom: "1px",
                                                        }}
                                                      />
                                                    </TooltipItem>
                                                  ) : null}
                                                  <span>{`${perDiem.employee.firstName} ${perDiem.employee.lastName}`}</span>
                                                </div>
                                                <span className="text-success">
                                                  {utilsHelper.formatCurrency(
                                                    perDiem.amount
                                                  )}
                                                  <FontAwesomeIcon
                                                    onClick={() =>
                                                      onDeleteCashAdvance(
                                                        perDiem.id
                                                      )
                                                    }
                                                    icon={faTrash}
                                                    className="text-danger cursor-pointer ml-2"
                                                  />
                                                </span>
                                              </ListGroupItem>
                                            )
                                          )}
                                          <ListGroupItem
                                            className="d-flex justify-content-between align-items-center py-2 border-left-0"
                                            tag="div"
                                          >
                                            <span className="font-weight-bold">
                                              Total
                                            </span>
                                            <strong className="font-weight-bold">
                                              {utilsHelper.formatCurrency(
                                                crewWorkDay.perDiems.reduce(
                                                  (p, c) => p + c.amount,
                                                  0
                                                )
                                              )}
                                            </strong>
                                          </ListGroupItem>
                                        </ListGroup>
                                      </Row>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          <div className="col-12 mt-2 border px-0 rounded">
                            <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                              <div className="d-flex align-items-center col-3 px-0"></div>
                              <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                General Expenses
                              </div>
                              <div className="d-flex align-items-center col-3 px-0 justify-content-end"></div>
                            </div>
                            {generalExpenses.length === 0 ? (
                              <div className="text-center text-muted my-2 small">
                                No general expenses have been recorded
                              </div>
                            ) : (
                              <div className="d-flex col-12 flex-column">
                                <Row className="d-flex flex-wrap">
                                  {generalExpenses.map((expense) => (
                                    <Expense
                                      onDelete={onDelete}
                                      onEdit={onEdit}
                                      expense={expense}
                                      key={expense.id}
                                    />
                                  ))}
                                </Row>
                              </div>
                            )}
                          </div>
                        </Collapse>
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </>
        )}
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                })
          }
        />
      ) : showExpensesReportModal ? (
        <ExpensesReportModal
          onClose={() => setShowExpensesReportModal(false)}
        />
      ) : showExpensesFlexReportModal ? (
        <JobExpensesFlexMonsterModal
          onClose={() => setShowExpensesFlexReportModal(false)}
        />
      ) : addWoExpense ? (
        <AddWoExpense
          onSubmit={() => {
            setAddWoExpense(false);
            setInformationModal({
              isOpen: true,
              title: `Add Expenses`,
              body: "Expenses added successfully.",
              onClose: () => {
                setInformationModal({ isOpen: false, title: "", body: "" });
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          }}
          onClose={() => setAddWoExpense(false)}
        />
      ) : updateWoExpense ? (
        <UpdateWoExpense
          expense={updateWoExpense}
          onSubmit={() => {
            setUpdateWoExpense(false);
            setInformationModal({
              isOpen: true,
              title: "Update Expense",
              body: "Expense updated successfully.",
              onClose: () => {
                setInformationModal({ isOpen: false, title: "", body: "" });
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          }}
          onClose={() => setUpdateWoExpense(false)}
        />
      ) : addWoCashAdvance ? (
        <AddWoCashAdvance
          onSubmit={() => {
            setAddWoCashAdvance(false);
            setInformationModal({
              isOpen: true,
              title: `Add Cash Advance`,
              body: "Cash advance added successfully.",
              onClose: () => {
                setInformationModal({ isOpen: false, title: "", body: "" });
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          }}
          onClose={() => setAddWoCashAdvance(false)}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Row>
  );
};
export default WorkOrderExpenses;
