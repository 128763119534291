import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import {
  faCheckCircle,
  faChevronLeft,
  faLayerGroup,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import GroupWosModal from "../../components/admin/GroupWosModal";

import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";
import Loader from "../../components/Loader";
import { workOrdersApi } from "../../services/workOrdersServices";
import { utilsHelper } from "../../helpers/utilsHelper";

import WorkOrderCrewDetails from "./WorkOrderCrewDetails";
import WorkOrderEquipments from "./WorkOrderEquipments";
import WorkOrderExpenses from "./WorkOrderExpenses";
import WorkOrderJobDetails from "./WorkOrderJobDetails";
import WorkOrderJobScope from "./WorkOrderJobScope";
import WorkOrderTimes from "./WorkOrderTimes";
import WorkOrderTravelLiving from "./WorkOrderTravelLiving";
import WorkOrderTimesheets from "./WorkOrderTimesheets";
import SafetyItems from "./SafetyItems";
import WorkOrderBudget from "./WorkOrderBudget";
import WorkOrderCommunicationLog from "./WorkOrderCommunicationLog";

import InformationModal from "./../../components/InformationModal";
import EditGroupingModal from "../../components/admin/EditGroupingModal";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import WorkOrderChecklist from "./WorkOrderChecklist";
import WorkOrderCWR from "./WorkOrderCWR";
import WorkOrderChangeOrders from "./WorkOrderChangeOrders";
import ChangeOrderAlert from "../../components/admin/ChangeOrderAlert";
import WorkOrderStatus from "../../components/admin/workOrderDetails/WorkOrderStatus";
import WorkOrderInspections from "./WorkOrderInspections";
import WorkOrderInspectionReports from "./WorkOrderInspectionReports";
import ConfirmationModal from "../../components/ConfirmationModal";
import EditStatusModal from "../../components/admin/workOrderDetails/EditStatusModal";
import WorkOrderInfo from "../../components/admin/workOrderDetails/WorkOrderInfo";
import NonJobProjectInfo from "../../components/admin/workOrderDetails/NonJobProjectInfo";
import { useAuth } from "../../providers/authProvider";
import { nonJobProjectsApi } from "../../services/nonJobProjectsServices";
import NonJobProjectModal from "../../components/admin/nonJobTime/NonJobProjectModal";
import EditDatesModal from "../../components/admin/workOrderDetails/EditDatesModal";
import { precisionWorkorderApi } from "../../services/precisionWorkorderService";
import WorkOrderDocuments from "./WorkOrderDocuments";

const NONJOB = "NONJOB";
const RUSH_JOB = "RUSH";
const ONEDAY_JOB = "ONEDAY";
const REGULAR_JOB = "REGULAR";

const JOB_TAB = "JOB_TAB";
const CREW_TAB = "CREW_TAB";
const TIME_TAB = "TIME_TAB";
const EXPENSES_TAB = "EXPENSES_TAB";
const TRAVEL_LIVING = "TRAVEL_LIVING";
const BUDGET_REPORTS_TAB = "BUDGET_REPORTS_TAB";
const JOB_SCOPE_TAB = "JOB_SCOPE_TAB";
const EQUIPMENT_TAB = "EQUIPMENT_TAB";
const TIMESHEETS_TAB = "TIMESHEETS_TAB";
const SAFETYITEMS_TAB = "SAFETYITEMS_TAB";
const CHECKLIST_TAB = "CHECKLIST_TAB";
const CWR_TAB = "CWR_TAB";

const CHANGE_ORDERS_TAB = "CHANGE_ORDERS_TAB";
const WORK_ORDER_DOCUMENTS_TAB = "WORK_ORDER_DOCUMENTS_TAB";
const INSPECTIONS_TAB = "INSPECTIONS_TAB";
const REPORTS_TAB = "REPORTS_TAB";
const COMMUNICATION_LOG_TAB = "COMMUNICATION_LOG_TAB";

const WO_TYPE_GROUP = "group";

const STATUS_COMPLETED = 4;
const STATUS_READY_FOR_ACCOUNTING = 5;
const STATUS_INVOICED = 6;
const STATUS_CANCELED = 10;

const DEPARTMENT_PRECSN = "PRECSN";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderDetails = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const navigate = useNavigate();
  const { workOrderId } = useParams();

  const [groupWoModal, setGroupWoModal] = useState();
  const [editStatusModal, setEditStatusModal] = useState();
  const [editDatesModal, setEditDatesModal] = useState();
  const [editGroupingModal, setEditGroupingModal] = useState();
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const toggle = (tab) => {
    if (workOrderDetails.activeTab !== tab) {
      setWorkOrderDetails({
        action: ACTIONS.CHANGE_TAB,
        payload: {
          tab,
        },
      });
    }
  };

  const onGroupWos = () => {
    const companies = Object.keys(
      workOrderDetails.workOrder.relatedWorkOrders.reduce((p, c) => {
        p[c.jobSourceId] = true;
        return p;
      }, {})
    );
    if (companies.length > 1) {
      return setInformationModal({
        isOpen: true,
        title: "Work Order Grouping",
        body: "Work Orders from different companies can't be grouped together.",
      });
    }
    setGroupWoModal(true);
  };

  const onEditGrouping = () => setEditGroupingModal(true);

  const onSubmitUngroup = (redirectToHome) => {
    setEditGroupingModal(false);
    if (redirectToHome) {
      navigate("/work-orders/all");
    } else {
      setWorkOrderDetails({
        action: ACTIONS.REFRESH,
      });
    }
  };

  // Load WorkOrder
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await workOrdersApi.getWorkOrder(workOrderId);
        setLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.GET_WORKORDER_SUCCESS,
          payload: {
            workOrder: data,
          },
        });
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Work Order Details",
          body: "There was an error with your request.",
          onClose: () => navigate("/work-orders/all"),
        });
      }
    };
    fetchData();
  }, [workOrderId, workOrderDetails.refresh, setWorkOrderDetails, navigate]);

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/work-orders/all");
    }
  };

  const onMarkAsRush = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await workOrdersApi.markAsRushJob({
          id: workOrderDetails.workOrder.id,
        });
        setLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Mark Job as Rush Job",
      body: `Are you sure you want to mark this job as a rush job?`,
    });
  };

  const onMarkAsOneDay = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await workOrdersApi.markAsOneDayJob({
          id: workOrderDetails.workOrder.id,
        });
        setLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Mark Job as One Day Job",
      body: `Are you sure you want to mark this job as a one day job?`,
    });
  };

  const onMarkAsRegular = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await workOrdersApi.markAsRegularJob({
          id: workOrderDetails.workOrder.id,
        });
        setLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Mark Job as Regular Job",
      body: `Are you sure you want to mark this job as a regular job?`,
    });
  };

  const onEditDates = async ({ startDate, endDate }) => {
    setEditDatesModal(false);
    setLoading(true);

    try {
      await precisionWorkorderApi.update({
        id: workOrderDetails.workOrder.id,
        startDate,
        endDate,
      });

      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Edit Work Order Dates",
        body: "Dates were updated successfully.",
        onClose: () => {
          setInformationModal({
            isOpen: false,
          });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (error) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Edit Work Order Dates",
        body: "There was an error with your request.",
      });
    }
  };

  const workOrderHasData = (workOrderDetails) => {
    return Object.keys(workOrderDetails.dates)?.some((date) => {
      return workOrderDetails.dates[date].crewWorkDays?.some(
        (crewWorkDay) =>
          crewWorkDay.workTimes?.length ||
          crewWorkDay.travelTimes?.length ||
          crewWorkDay.perDiems?.length ||
          crewWorkDay.expenses?.length
      );
    });
  };

  const onEditStatus = async (statusId) => {
    setEditStatusModal(false);
    setLoading(true);
    try {
      if (Number(statusId) === STATUS_CANCELED) {
        if (workOrderHasData(workOrderDetails)) {
          return setInformationModal({
            isOpen: true,
            title: "Edit Work Order Status",
            body: "You can't cancel a work order with data.",
            onClose: () => {
              setInformationModal({
                isOpen: false,
              });
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        }
      }

      await workOrdersApi.updateWorkOrderStatus({
        statusId,
        id: workOrderId,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Edit Work Order Status",
        body: "Status was updated successfully.",
        onClose: () => {
          setInformationModal({
            isOpen: false,
          });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Edit Work Order Status",
        body: "There was an error with your request.",
      });
    }
  };

  const onCloseProject = async () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        try {
          await workOrdersApi.updateWorkOrderStatus({
            statusId: STATUS_COMPLETED,
            id: workOrderId,
          });
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Close Out Project",
            body: "Project closed out successfully.",
            onClose: () => {
              setInformationModal({
                isOpen: false,
              });
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Close Out Project",
            body: "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Close Out Project",
      body: `Are you sure you want to mark this job as closed?`,
    });
  };

  const onEdit = () => setEditModal(workOrderDetails.workOrder);

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        nonJobProjectsApi.deleteNonJobProject({
          id: workOrderDetails.workOrder.id,
        });
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });

        setInformationModal({
          isOpen: true,
          title: "Delete Project",
          body: "Project deleted successfully.",
          onClose: () => {
            setInformationModal({
              isOpen: false,
            });
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
            goBack();
          },
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Delete Project",
      body: `Are you sure you want to delete ${workOrderDetails.workOrder.workOrderNumber}?`,
      confirmColor: "danger",
    });
  };

  const countChangeOrders = workOrderDetails.workOrder?.changeOrders?.length;

  const isDepartmentPRECSN =
    workOrderDetails.workOrder?.department === DEPARTMENT_PRECSN;

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Header className="d-flex align-items-center justify-content-between mb-3">
        <HeaderTitle className="d-flex flex-column align-items-start col-6 px-0">
          <span className="col-12 px-0 col-lg-6 text-dark">
            Work Order Information
          </span>
          <Button
            size="sm"
            className="rounded mt-1 d-flex align-items-center"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            <span>Back</span>
          </Button>
        </HeaderTitle>
        {!loading ? (
          <div>
            {workOrderDetails.workOrder?.workOrderType === REGULAR_JOB &&
            utilsHelper.isExecutive(authContext) &&
            workOrderDetails.workOrder?.statusId !== STATUS_INVOICED &&
            workOrderDetails.workOrder?.statusId !==
              STATUS_READY_FOR_ACCOUNTING ? (
              <>
                <Button
                  className="rounded"
                  size="sm"
                  color="danger"
                  onClick={onMarkAsRush}
                >
                  Mark as Rush Job
                </Button>
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="danger"
                  onClick={onMarkAsOneDay}
                >
                  Mark as One Day Job
                </Button>
              </>
            ) : workOrderDetails.workOrder?.workOrderType === RUSH_JOB ? (
              <>
                {workOrderDetails.workOrder?.statusId !==
                  STATUS_READY_FOR_ACCOUNTING &&
                  utilsHelper.isExecutive(authContext) &&
                  workOrderDetails.workOrder?.statusId !== STATUS_INVOICED && (
                    <Button
                      className="rounded mr-2"
                      size="sm"
                      color="secondary"
                      onClick={onMarkAsRegular}
                    >
                      Mark as Regular Job
                    </Button>
                  )}
                <Badge color="danger" pill>
                  Rush Job
                </Badge>
              </>
            ) : workOrderDetails.workOrder?.workOrderType === ONEDAY_JOB ? (
              <>
                {workOrderDetails.workOrder?.statusId !==
                  STATUS_READY_FOR_ACCOUNTING &&
                  utilsHelper.isExecutive(authContext) &&
                  workOrderDetails.workOrder?.statusId !== STATUS_INVOICED && (
                    <Button
                      className="rounded mr-2"
                      size="sm"
                      color="secondary"
                      onClick={onMarkAsRegular}
                    >
                      Mark as Regular Job
                    </Button>
                  )}
                <Badge color="danger" pill>
                  One Day Job
                </Badge>
              </>
            ) : workOrderDetails.workOrder?.workOrderType === NONJOB &&
              !utilsHelper.isReadOnly(authContext) ? (
              <>
                {workOrderDetails.workOrder.statusId !== STATUS_COMPLETED ? (
                  <Button
                    className="rounded ml-2"
                    size="sm"
                    color="info"
                    onClick={onCloseProject}
                  >
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    <span>Close Out Project</span>
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  color="warning"
                  className="rounded mx-2"
                  onClick={onEdit}
                >
                  <FontAwesomeIcon icon={faPen} className="mr-1" />
                  <span>Edit</span>
                </Button>
                <Button
                  size="sm"
                  className="rounded"
                  color="danger"
                  onClick={onDelete}
                >
                  <FontAwesomeIcon icon={faTrash} className="mr-1" />
                  <span>Delete</span>
                </Button>
              </>
            ) : null}
            {workOrderDetails.workOrder?.workOrderType !== NONJOB &&
            !utilsHelper.isReadOnly(authContext) ? (
              <>
                <Button
                  className="rounded ml-2"
                  size="sm"
                  color="warning"
                  onClick={() => setEditStatusModal(true)}
                >
                  Edit Status
                </Button>

                {isDepartmentPRECSN && (
                  <Button
                    className="rounded ml-2"
                    size="sm"
                    color="warning"
                    onClick={() => setEditDatesModal(true)}
                  >
                    Edit Dates
                  </Button>
                )}
              </>
            ) : null}
          </div>
        ) : null}
      </Header>
      <Row>
        {!workOrderDetails.workOrder || loading ? (
          <Loader />
        ) : (
          <Col className="flex-grow-1 d-flex flex-column">
            <Card>
              <CardHeader>
                {workOrderDetails.workOrder.workOrderType === NONJOB ? (
                  <NonJobProjectInfo />
                ) : (
                  <WorkOrderInfo />
                )}
              </CardHeader>
              {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                <CardBody className="overflow-x-auto">
                  <WorkOrderStatus />
                </CardBody>
              ) : null}
            </Card>
            {workOrderDetails.workOrder.type === WO_TYPE_GROUP ? (
              <Card className="mb-4">
                <CardBody className="d-flex align-items-center justify-content-between py-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>Grouped Work Orders:</strong>
                    <div className="d-flex ml-1 align-items-center">
                      {workOrderDetails.workOrder.groupedWorkOrders?.map(
                        (wo) => (
                          <Badge
                            onClick={() =>
                              navigate(`/work-orders/details/${wo.id}`)
                            }
                            key={wo.id}
                            className="mx-1 cursor-pointer"
                            color="warning"
                          >
                            {wo.workOrderNumber}
                          </Badge>
                        )
                      )}
                    </div>
                  </div>
                  {!utilsHelper.isReadOnly(authContext) ? (
                    <Button
                      color="warning"
                      onClick={onEditGrouping}
                      size="sm"
                      className="rounded d-flex align-items-center"
                    >
                      <FontAwesomeIcon icon={faPen} className="mr-2" />
                      <span>Edit Grouping</span>
                    </Button>
                  ) : null}
                </CardBody>
              </Card>
            ) : !isDepartmentPRECSN &&
              !workOrderDetails.workOrder.woParentId &&
              workOrderDetails.workOrder.relatedWorkOrders?.filter(
                (wo) => wo.id !== parseInt(workOrderId)
              )?.length ? (
              <Card className="mb-4">
                <CardBody className="d-flex align-items-center justify-content-between py-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>Related Work Orders:</strong>
                    <div className="d-flex ml-1 align-items-center">
                      {workOrderDetails.workOrder.relatedWorkOrders
                        .filter((wo) => wo.id !== parseInt(workOrderId))
                        .map((wo) => (
                          <Badge
                            onClick={() =>
                              navigate(`/work-orders/details/${wo.id}`)
                            }
                            key={wo.id}
                            className="mx-1 cursor-pointer"
                            color="warning"
                          >
                            {wo.workOrderNumber}
                          </Badge>
                        ))}
                    </div>
                  </div>
                  {!utilsHelper.isReadOnly(authContext) ? (
                    <Button
                      color="warning"
                      onClick={onGroupWos}
                      size="sm"
                      className="rounded d-flex align-items-center"
                    >
                      <FontAwesomeIcon icon={faLayerGroup} className="mr-2" />
                      <span>Group Work Orders</span>
                    </Button>
                  ) : null}
                </CardBody>
              </Card>
            ) : null}

            {workOrderDetails.workOrder.woParentId ? (
              <Card className="mb-4">
                <CardBody className="d-flex align-items-center justify-content-between py-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <strong>Work Order Grouped</strong>
                    <div className="d-flex ml-1 align-items-center">
                      <Badge
                        onClick={() =>
                          navigate(
                            `/work-orders/details/${workOrderDetails.workOrder.woParentId}`
                          )
                        }
                        className="mx-1 cursor-pointer"
                        color="warning"
                      >
                        Click here to see the Group
                      </Badge>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : null}
            <ChangeOrderAlert />
            <div className="tab tab-vertical h-100">
              <Nav tabs className="cursor-pointer border-bottom">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === JOB_TAB,
                    })}
                    onClick={() => toggle(JOB_TAB)}
                  >
                    Job Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === CREW_TAB,
                    })}
                    onClick={() => toggle(CREW_TAB)}
                  >
                    Crews
                  </NavLink>
                </NavItem>
                {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active:
                          workOrderDetails.activeTab === COMMUNICATION_LOG_TAB,
                      })}
                      onClick={() => toggle(COMMUNICATION_LOG_TAB)}
                    >
                      Com. Log
                    </NavLink>
                  </NavItem>
                ) : null}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === TIME_TAB,
                    })}
                    onClick={() => toggle(TIME_TAB)}
                  >
                    Time
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === EXPENSES_TAB,
                    })}
                    onClick={() => toggle(EXPENSES_TAB)}
                  >
                    Expenses
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === TRAVEL_LIVING,
                    })}
                    onClick={() => toggle(TRAVEL_LIVING)}
                  >
                    Travel & Living
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === BUDGET_REPORTS_TAB,
                    })}
                    onClick={() => toggle(BUDGET_REPORTS_TAB)}
                  >
                    Budget/Reports
                  </NavLink>
                </NavItem>
                {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                  <>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: workOrderDetails.activeTab === JOB_SCOPE_TAB,
                        })}
                        onClick={() => toggle(JOB_SCOPE_TAB)}
                      >
                        Job Scope
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: workOrderDetails.activeTab === EQUIPMENT_TAB,
                        })}
                        onClick={() => toggle(EQUIPMENT_TAB)}
                      >
                        Equipment
                      </NavLink>
                    </NavItem>
                  </>
                ) : null}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: workOrderDetails.activeTab === TIMESHEETS_TAB,
                    })}
                    onClick={() => toggle(TIMESHEETS_TAB)}
                  >
                    Timesheets
                  </NavLink>
                </NavItem>
                {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                  <>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            workOrderDetails.activeTab === CHANGE_ORDERS_TAB,
                        })}
                        onClick={() => toggle(CHANGE_ORDERS_TAB)}
                      >
                        Change Orders
                        {countChangeOrders ? (
                          <span className="badge badge-pill badge-danger ml-2">
                            {countChangeOrders}
                          </span>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            workOrderDetails.activeTab ===
                            WORK_ORDER_DOCUMENTS_TAB,
                          "d-flex align-items-center justify-content-between": true,
                        })}
                        onClick={() => toggle(WORK_ORDER_DOCUMENTS_TAB)}
                      >
                        Job Documents
                        {workOrderDetails.workOrder.totalDocuments > 0 ? (
                          <span className="badge badge-pill badge-danger ml-2">
                            {workOrderDetails.workOrder.totalDocuments}
                          </span>
                        ) : null}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            workOrderDetails.activeTab === INSPECTIONS_TAB,
                        })}
                        onClick={() => toggle(INSPECTIONS_TAB)}
                      >
                        Inspections
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: workOrderDetails.activeTab === REPORTS_TAB,
                        })}
                        onClick={() => toggle(REPORTS_TAB)}
                      >
                        Inspection Reports
                      </NavLink>
                    </NavItem>
                    {workOrderDetails.workOrder.statusId ===
                    STATUS_COMPLETED ? (
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active:
                              workOrderDetails.activeTab === CHECKLIST_TAB,
                          })}
                          onClick={() => toggle(CHECKLIST_TAB)}
                        >
                          Checklist
                        </NavLink>
                      </NavItem>
                    ) : null}
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: workOrderDetails.activeTab === CWR_TAB,
                        })}
                        onClick={() => toggle(CWR_TAB)}
                      >
                        CWR
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active:
                            workOrderDetails.activeTab === SAFETYITEMS_TAB,
                        })}
                        onClick={() => toggle(SAFETYITEMS_TAB)}
                      >
                        Safety Items
                      </NavLink>
                    </NavItem>
                  </>
                ) : null}
              </Nav>
              <TabContent
                activeTab={workOrderDetails.activeTab}
                className="h-100"
              >
                <TabPane tabId={JOB_TAB}>
                  {workOrderDetails.activeTab === JOB_TAB ? (
                    <WorkOrderJobDetails />
                  ) : null}
                </TabPane>
                <TabPane tabId={TIME_TAB}>
                  {workOrderDetails.activeTab === TIME_TAB ? (
                    <WorkOrderTimes />
                  ) : null}
                </TabPane>
                <TabPane tabId={EXPENSES_TAB}>
                  {workOrderDetails.activeTab === EXPENSES_TAB ? (
                    <WorkOrderExpenses />
                  ) : null}
                </TabPane>
                <TabPane tabId={TRAVEL_LIVING}>
                  {workOrderDetails.activeTab === TRAVEL_LIVING ? (
                    <WorkOrderTravelLiving />
                  ) : null}
                </TabPane>
                <TabPane tabId={BUDGET_REPORTS_TAB}>
                  {workOrderDetails.activeTab === BUDGET_REPORTS_TAB ? (
                    <WorkOrderBudget />
                  ) : null}
                </TabPane>
                <TabPane tabId={JOB_SCOPE_TAB}>
                  {workOrderDetails.activeTab === JOB_SCOPE_TAB ? (
                    <WorkOrderJobScope />
                  ) : null}
                </TabPane>
                <TabPane tabId={CREW_TAB}>
                  {workOrderDetails.activeTab === CREW_TAB ? (
                    <WorkOrderCrewDetails />
                  ) : null}
                </TabPane>
                <TabPane tabId={COMMUNICATION_LOG_TAB}>
                  {workOrderDetails.activeTab === COMMUNICATION_LOG_TAB ? (
                    <WorkOrderCommunicationLog />
                  ) : null}
                </TabPane>
                <TabPane tabId={EQUIPMENT_TAB}>
                  {workOrderDetails.activeTab === EQUIPMENT_TAB ? (
                    <WorkOrderEquipments />
                  ) : null}
                </TabPane>
                <TabPane tabId={TIMESHEETS_TAB}>
                  {workOrderDetails.activeTab === TIMESHEETS_TAB ? (
                    <WorkOrderTimesheets />
                  ) : null}
                </TabPane>
                <TabPane tabId={CHECKLIST_TAB}>
                  {workOrderDetails.activeTab === CHECKLIST_TAB ? (
                    <WorkOrderChecklist />
                  ) : null}
                </TabPane>
                <TabPane tabId={CWR_TAB}>
                  {workOrderDetails.activeTab === CWR_TAB ? (
                    <WorkOrderCWR />
                  ) : null}
                </TabPane>
                <TabPane tabId={CHANGE_ORDERS_TAB}>
                  {workOrderDetails.activeTab === CHANGE_ORDERS_TAB ? (
                    <WorkOrderChangeOrders />
                  ) : null}
                </TabPane>
                <TabPane tabId={WORK_ORDER_DOCUMENTS_TAB}>
                  {workOrderDetails.activeTab === WORK_ORDER_DOCUMENTS_TAB ? (
                    <WorkOrderDocuments />
                  ) : null}
                </TabPane>
                <TabPane tabId={INSPECTIONS_TAB}>
                  {workOrderDetails.activeTab === INSPECTIONS_TAB ? (
                    <WorkOrderInspections />
                  ) : null}
                </TabPane>
                <TabPane tabId={REPORTS_TAB}>
                  {workOrderDetails.activeTab === REPORTS_TAB ? (
                    <WorkOrderInspectionReports />
                  ) : null}
                </TabPane>
                <TabPane tabId={SAFETYITEMS_TAB}>
                  {workOrderDetails.activeTab === SAFETYITEMS_TAB ? (
                    <SafetyItems />
                  ) : null}
                </TabPane>
              </TabContent>
            </div>
          </Col>
        )}
      </Row>
      {groupWoModal ? (
        <GroupWosModal onClose={() => setGroupWoModal(false)} />
      ) : editGroupingModal ? (
        <EditGroupingModal
          onClose={() => setEditGroupingModal(false)}
          onSubmit={onSubmitUngroup}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : editStatusModal ? (
        <EditStatusModal
          onClose={() => setEditStatusModal(false)}
          onSubmit={onEditStatus}
        />
      ) : editDatesModal ? (
        <EditDatesModal
          onClose={() => setEditDatesModal(false)}
          onSubmit={onEditDates}
        />
      ) : editModal ? (
        <NonJobProjectModal
          nonJobProject={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setInformationModal({
              isOpen: true,
              title: `Update Non Job Project`,
              body: "Non Job Project updated successfully",
            });
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkOrderDetails;
